import * as React from 'react';

import Section from '../components/section';
import Layout from '../components/layout';

import ImageAvatarSteve from '../components/imageAvatarSteve';

const IndexPage = () => (
  <Layout>
    <div className="flex flex-col flex-1">
      <Section>
        <h1 className="text-theme-red flex-1">What We Do</h1>
        <p className="text-theme-red">
          At Starter4Ten we care about using tech to get the best results for
          customers while making team work, fun, productive, and innovative
        </p>
      </Section>
      <Section red={true} center={true}>
        <h1 className="text-white">Who We Are</h1>
        <div className="flex align-middle pt-4">
          <div style={{ width: 150, height: 150 }}>
            <ImageAvatarSteve />
          </div>
          <div className="flex flex-col text-left text-white pl-4">
            <h2 className="mb-4">Steve Mosley</h2>
            <h4>About Steve</h4>
            <p className="mb-4">Steve hails from Dunedin ...currently in Wellington, lots more to say</p>
            <h4>Customer Testimonial</h4>
            <p className="mb-4">
              Steve is truly one of the best developers who we’ve worked with in
              New Zealand. More importantly he’s a genuinely great person to
              work with; He cares passionately about building things the right
              way, helps build a productive work environment and most
              importantly gets results for customers. I recommend him very
              highly.
            </p>
          </div>
        </div>
      </Section>
    </div>
  </Layout>
);

export default IndexPage;
