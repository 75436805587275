import * as React from 'react';
import classNames from 'classnames';

const Section = ({
  red = false,
  center = false,
  children,
}: {
  red?: boolean;
  center?: boolean;
  children: React.ReactNode;
}) => (
  <section
    className={classNames(
      'px-8',
      'py-4',
      red ? 'bg-theme-red' : 'bg-white',
      center && 'text-center'
    )}
  >
    {children}
  </section>
);

export default Section;
